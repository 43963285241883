import React,{useState,useRef,useEffect,useContext} from 'react';
import Cover from './Cover';
import Modal from './Modal';
import Photo from './Photo';
import male from '../male.svg';
import female from '../female.svg';
import {QueryContext} from '../context/queryProvider';
const Choser = () => {
    const [query,setQuery] = useContext(QueryContext);
    const genderClick = g => {
        setQuery(prev => (
            {
                ...prev,
                data: {
                    ...prev.data,
                    gender: g
                }
            }
        ))
        
    }

return (
    <div className="wrap-selector gender-selector">
            
        <ul>
            <li onClick={()=>genderClick("male")}>
                <img src={male} alt=""/>
                <p>Male</p>
            </li>
            <li onClick={()=>genderClick("female")}>
                <img src={female} alt=""/>
                <p>Female</p>
            </li>
        </ul>
                
    </div>
)
};
const Form = () => {
    const [query,setQuery] = useContext(QueryContext);
    const [isModalOpen,setModal] = useState(false);
    const modal = useRef(null);
    const onDone = () => {
        setModal(false);
        //console.log("done");
    }
    const handleDetails = e => {
        switch(e.target.id){
            case "name":
                setQuery(prev => (
                    {
                        ...prev,
                        data: {
                            ...prev.data,
                            name: e.target.value === "" ? "Name" : e.target.value
                        }
                    }
                ));
                break;
            case "address":
                setQuery(prev => (
                    {
                        ...prev,
                        data: {
                            ...prev.data,
                            address: e.target.value === "" ? "Address" : e.target.value
                        }
                    }
                ));
                break;
            case "dod":
                setQuery(prev => (
                    {
                        ...prev,
                        data: {
                            ...prev.data,
                            date: e.target.value === "" ? "Date" : e.target.value
                        }
                    }
                ));
                break;
            case "age":
                setQuery(prev => (
                    {
                        ...prev,
                        data: {
                            ...prev.data,
                            age: e.target.value === "" ? "Age" : e.target.value
                        }
                    }
                ));
                break;
        }
    }

    return(
        <>
        <div className="wrap-selector info">
            <ul className="form">
                    <li>
                    <div className="form-group">
                        <label className="form-label">Name</label>
                        <input className="form-input" id="name" type="text" placeholder="Name" onBlur={handleDetails}/>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Address</label>
                        <input className="form-input" id="address" type="text" placeholder="Address" onBlur={handleDetails}/>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Date of Death</label>
                        <input className="form-input" id="dod" type="text" placeholder="12/03/2010" onBlur={handleDetails}/>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Age</label>
                        <input className="form-input" id="age" type="text" placeholder="Age" onBlur={handleDetails}/>
                    </div>
                    </li>
                </ul>
        </div>
        <div className="wrap-selector-mobile">
            <button className="btn btn-primary" onClick={() => setModal(true)}>+</button>
            
            {
                isModalOpen && (
                    <Modal title="Details of Deceased" onDone={onDone} modal={modal} btnName="Okay">
                        <ul className="form">
                            <li>
                            <div className="form-group">
                                <label className="form-label">Name</label>
                                <input className="form-input" type="text" placeholder="Name" value={query.data.name}/>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Address</label>
                                <input className="form-input" type="text" placeholder="Address"/>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Date of Death</label>
                                <input className="form-input" type="text" placeholder="12/03/2010"/>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Age</label>
                                <input className="form-input" type="text" placeholder="Age"/>
                            </div>
                            </li>
                        </ul>
                    </Modal>
                )
            }
            
        </div>
        </>
    )
}
const Choice = ({step,setTitle,width,height,setPreview,setIsInside,loaded,setLoading,stepPosition}) => {

    useEffect(() => {
        step.length !== 0 && setTitle(step.step_name);
        step.length !== 0 && setPreview(step.step_preview);
        //console.log(step);
    }, [step]);
   
    return (

      
        <>
        {
            step.step_type === "cover" && <Cover body={step.step_body_content} width={width} height={height} setIsInside={setIsInside} loaded={loaded} setLoading={setLoading} stepPosition={stepPosition}/>
        }
        {
            step.step_type === "radio" && <Choser />
        }
        {
            step.step_type === "form" && <Form />
        }
        {
            step.step_type === "photo" && <Photo />
        }
        {
            step.step_type === "Cover Action" && <Cover body={step.step_body_content} width={width} height={height} hasAction={true} setIsInside={setIsInside}/>
        }
        </>
        
        
       // <Form />
       
        
    );
}

export default Choice;
