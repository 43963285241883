export const getProducts = async (url) => {
    
    const data = await fetch(url).then(res => res.json());
    return data;
}
export const products = [
    {
        slug: "memoriam-cards",
        title: "Memoriam Cards",
        width: 96,
        height: 143,
        image: "memoriams.jpg",
        subproducts: [
            {
                slug: "traditional-memoriam-cards",
                title: "Traditional",
                width: 96,
                height: 143,
                image:"memCard_type1.jpg"
            },
            {
                slug: "picture-memoriam-cards",
                title: "Picture Styled",
                width: 96,
                height: 143,
                image: "memCard_type2.jpg"
            }
        ]
    },
    {
        slug: "memories-style-memorial-card",
        title: "Memories Style Memorial Card",
        width: 96,
        height: 43,
        image: "memoriesStyle.jpg",
        subproducts: []
    },
    {
        slug: "bookmarks",
        title: "Bookmarks",
        width: 96,
        height: 143,
        image: "bookmakers.jpg",
        subproducts: []
    },
    {
        slug: "memorial-style-bookmarks",
        title: "Memorial Style Bookmarks",
        width: 96,
        height: 143,
        image: "bookmakers_memories.jpg",
        subproducts: []
    },
    {
        slug: "acknowledgement-cards",
        title: "Acknowledgement Cards",
        width: 96,
        height: 143,
        image: "acknowledgements.jpg",
        subproducts: []
    },
    {
        slug: "keyrings",
        title: "Keyrings",
        width: 96,
        height: 143,
        image: "keyrings.jpg",
        subproducts: []
    },
    {
        slug: "wallets",
        title: "Wallets",
        width: 96,
        height: 143,
        image: "Wallet_Style_Memoriam_Card.png",
        subproducts: []
    }
];
export const getSteps = () => {
    const apiUrl = 'http://jjlalor-ie.stackstaging.com/app/backend/api/steps/MemorialCards/TraditionalStyleMemoriamCard';
        fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
        });
}

export const steps = [
    {
        cat_id: "MemoriesStyleMemorialCards",
        sub_cat_id: "",
        steps: [
            {
                step_pos: 1,
                step_name: "Choose Front Cover",
                step_body_type: "Cover",
                step_desc: "",
                step_preview: "pre_front.jpg",
                step_body_content: [
                    {
                        image: "front1.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "front2.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "front3.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "front4.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "front5.jpg",
                        name: "w_10_w"
                    }
                ]
            },
            {
                step_pos: 2,
                step_name: "Choose Back Cover",
                step_body_type: "Cover",
                step_desc: "",
                step_preview: "pre_back.jpg",
                step_body_content: [
                    {
                        image: "back1.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "back2.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "back3.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "back4.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "back5.jpg",
                        name: "w_10_w"
                    }
                ]
            },
            {
                step_pos: 4,
                step_name: "Inside Left Details",
                step_body_type: "Radio",
                step_desc: "Choose gender of Deceased",
                step_preview: "pre_inside_left.jpg",
            },
            {
                step_pos: 3,
                step_name: "Choose Border Style",
                step_body_type: "Cover",
                step_desc: "Choose a border style for your photo.",
                step_preview: "pre_inside_left.jpg",
                step_body_content: [
                    {
                        image: "border_1.jpg",
                        name: "w_10_w",
                        type: "rounded_fade"
                    },
                    {
                        image: "border_2.jpg",
                        name: "w_10_w",
                        type: "single_border"
                    },
                    {
                        image: "border_3.jpg",
                        name: "w_10_w",
                        type: "normal"
                    },
                    {
                        image: "border_4.jpg",
                        name: "w_10_w",
                        type: "rounded"
                    }
                ]
            },
            {
                step_pos: 5,
                step_name: "Choose Verse",
                step_body_type: "Cover",
                step_desc: "Choose a verse.",
                step_preview: "pre_inside_left.jpg",
                step_body_content: [
                    {
                        image: "verse_1.jpg",
                        name: "w_10_w",
                        text: "Christ has died; Christ is rise n; Christ will come again."
                    },
                    {
                        image: "verse_2.jpg",
                        name: "w_10_w",
                        text: "Those who died in grace, go no further from us than God and God is very near."
                    },
                    {
                        image: "verse_3.jpg",
                        name: "w_10_w",
                        text: "Christ has died; Christ is rise n; Christ will come again."
                    },
                    {
                        image: "verse_1.jpg",
                        name: "w_10_w",
                        text: "Those who died in grace, go no further from us than God and God is very near."
                    }
                ]
            },
            {
                step_pos: 6,
                step_name: "Inside Left Details",
                step_body_type: "Form",
                step_desc: "Details of deceased to appear on card.",
                step_preview: "pre_inside_left.jpg",
            },
            {
                step_pos: 7,
                step_name: "Choose Photo",
                step_body_type: "Photo",
                step_desc: "Please click browse to upload and crop a photo for your card.",
                step_preview: "pre_inside_left.jpg",
            },
            {
                step_pos: 8,
                step_name: "Inside Right Details",
                step_body_type: "Cover Action",
                step_desc: "",
                step_preview: "pre_inside_left.jpg",
                step_body_content: [
                    {
                        image: "v_r_1.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "v_r_2.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "v_r_3.jpg",
                        name: "w_10_w"
                    },
                    {
                        image: "v_r_4.jpg",
                        name: "w_10_w"
                    }
                ]
            }
        ]
    }
]

export const template = [
    {
        cover_id: 1,
        image: "v_r_4.jpg",
        cover_type: "inside_left",
        html: `
        <html>
        <style>
            .rounded{
                border-radius:100%;
            }
            .rounded_fade{
                border-radius: 100%;
                box-shadow: inset 0px 0px 10px 10px rgba(255,255,255,1);
            }
            .single_border{
                border-radius: 20px;
                padding: 10px;
                border: 5px solid #a28a2a;
            }
        </style>
        <table>
        <tr style="height:{{half_height}}px">
            <td style="padding-top:0;">
            <div style="
            width: {{photo_width}}px;
            height: {{photo_height}}px;
            margin: auto;
            overflow: hidden;
            background: #ddd url('./female.svg') no-repeat center center;
            background-size: 130%;
            background-image:url({{img_src}});" class="{{border_style}}"></div>
            </td>
        </tr>
        
        <tr style="height:{{half_height}}px">
            <td>
                <h1 style="text-align:center;text-transform: uppercase;font-size:{{title_size}}px;">In Loving Memory</h1>
                <p style="text-align:center;margin:0;line-height:{{title_size}}px;" class="breaker">of</p>
                <h2 style="text-align:center;margin:0;line-height:{{big_size}}px;font-size:{{big_size}}px;">{{name}}</h2>
                <p style="text-align:center;margin:0;line-height:{{title_size}}px;font-size:{{normal_size}}px;">{{address}}</p>
                <p style="text-align:center;margin:0;line-height:{{title_size}}px;font-size:{{normal_size}}px;">who died</p>
                <p style="text-align:center;margin:0;line-height:{{title_size}}px;font-size:{{normal_size}}px;">on the {{date}}</p>
                <p style="text-align:center;margin:0;line-height:{{title_size}}px;font-size:{{normal_size}}px;">Aged {{age}} Years</p>
                <p style="text-align:center;margin:0;line-height:{{title_size}}px;font-size:{{normal_size}}px;">R.I.P.</p>
                <p style="text-align:center;font-size:{{normal_size}}px;">{{verse}}</p>
            </td>
        <tr/>
        
        
        
        </table>
        </html>`
    }
]
export const price = [
    {
        id:1,
        cat_id: 1,
        sub_cat_id: null,
        category: "Bookmarks",
        sub_category: "",
        quantity: [25,35,50,75,100,125,150,200,250,300,400],
        prices: [92,109,131,164,197,231,250,317,382,438,535],
        additional_qty: 100,
        additional_price: 99,
        includes: "plastic finish"

    },
    {
        id:1,
        cat_id: 2,
        sub_cat_id: null,
        category: "Acknowledgement Cards",
        sub_category: "",
        quantity: [25,35,50,75,100,125,150,200,250,300,400],
        prices: [92,109,131,164,197,231,250,317,382,438,535],
        additional_qty: 100,
        additional_price: 99,
        includes: "envelopes"

    }
]
