import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from './Modal';
import {QueryContext} from '../context/queryProvider';
import useLocalStorage from '../hooks/useLocalStorage';
function generateDownload(canvas, crop) {
  console.log(canvas);
  if (!crop || !canvas) {
    return;
  }
  console.log(canvas.toDataURL('image/png'));
  // canvas.toBlob(
  //   (blob) => {
  //     const previewUrl = window.URL.createObjectURL(blob);

  //     const anchor = document.createElement('a');
  //     anchor.download = 'cropPreview.png';
  //     anchor.href = URL.createObjectURL(blob);
  //     console.log(anchor.href);
  //     // anchor.click();

  //     // window.URL.revokeObjectURL(previewUrl);
  //   },
  //   'image/png',
  //   1
  // );
}
const Photo = () => {
  const [photo, setPhoto] = useLocalStorage('photo',"");
  const [upImg, setUpImg] = useState();
  const [isModalOpen,setModal] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const modal = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 250 / 300 });
  const [completedCrop, setCompletedCrop] = useState(null);

    const template = `
    <h1>Inside Left</h1>
    <img src="" alt=""/>
    `;
  const onSelectFile = (e) => {
    setModal(true);
    console.log("opend");
    if (e.target.files && e.target.files.length > 0) {
      
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onDone = e => {
    modal.current.classList.remove('active');
    setModal(false);
    console.log(previewCanvasRef.current.toDataURL('image/png'));
    setPhoto(previewCanvasRef.current.toDataURL('image/png'))
  //   setQuery(prev => (
  //     {
  //         ...prev,
  //         data: {
  //             ...prev.data,
  //             photo: previewCanvasRef.current.toDataURL('image/png')
  //         }
  //     }
  // ))
    //generateDownload(previewCanvasRef.current, completedCrop,template);
    //generateDownload(previewCanvasRef.current, completedCrop)
   // console.log(canvas.toDataURL('image/png'));
  }
  // useEffect(() => {
  //   document.querySelector(".photo-thumbnail").addEventListener("click",function(){
  //     document.getElementById("photo").click();
      
  //   });
  // }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
    return (

        <div>
            <div>
                <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                }}
                />
            </div>
           <div className="photo-thumbnail">
                <label htmlFor="photo">Click to upload</label>
                <input type="file" accept="image/*" onChange={onSelectFile} onClick={onSelectFile} style={{display:"none"}} id="photo"/>
               
            </div>
            
            {
                isModalOpen && (
                    <Modal title="Crop Photo" onDone={onDone} modal={modal} btnName="Done Cropping" canvas={previewCanvasRef.current}>
                        <ReactCrop
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                    </Modal>
                )
            }
            
            
            {/* <button
                type="button"
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() =>
                generateDownload(previewCanvasRef.current, completedCrop)
                }
            >
                Download cropped image
            </button>  */}
        </div>
    );
}

export default Photo;
