import React,{useEffect,useState} from 'react';
import {Link} from "react-router-dom";
//import {products} from "../API/data";
import OffNav from './OffNav';
import logo from '../footer-logo.png';
const Nav = () => {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        
        const apiUrl = `${process.env.REACT_APP_BASE_URL}backend/api/get_products`;
        fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => setProducts(data));
    }, []);
    return (
        <header className="navbar">
            <section className="navbar-section logo">
                <h1><Link to="/app" className="btn btn-link"><img src={logo} alt="jj lalor" className="logo-main" /></Link></h1>
            </section>
                <section className="navbar-section menu-bar">
                    <ul className="nav nav-desktop">
                        <li><Link to="/app" className="btn btn-link">Home</Link></li>
                        <li><Link to="/app/about" className="btn btn-link">About Us</Link></li>
                        <li>
                        <div className="dropdown">
                        <a className="btn btn-link dropdown-toggle" tabIndex="0">
                            Products <i className="icon icon-caret"></i>
                        </a>
                        <ul className="menu nav">
                            {
                                products.map((p) => (
                                    <li key={p.slug}>
                                        <Link to={`/app/product/${p.slug}`} className="btn btn-link">{p.title}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                        </div>
                        
                        </li>
                        <li><Link to="/app/pricing" className="btn btn-link">Pricing</Link></li>
                        <li><Link to="/app/cart" className="btn btn-link">Cart</Link></li>
                    </ul>
                    <OffNav>
                        <ul className="nav">
                            <li><Link to="/app" className="btn btn-link">Home</Link></li>
                            <li><Link to="/app/about" className="btn btn-link">About Us</Link></li>
                            <li>
                                
                            <a className="btn btn-link disable">Products</a>
                            <ul className="nav">
                                
                                {
                                    products.map((p) => (
                                        <li key={p.slug}>
                                            <Link to={`/app/product/${p.slug}`} className="btn btn-link">{p.title}</Link>
                                        </li>
                                    ))
                                }

                            </ul>
                            </li>
                            <li><Link to="/app/pricing" className="btn btn-link">Pricing</Link></li>
                            <li><Link to="/app/cart" className="btn btn-link">Cart</Link></li>
                        </ul>
                    </OffNav>
                    
                    
                    
                    
                   
                </section>
        </header>
    );
}

export default Nav;
