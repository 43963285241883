import React from 'react';
import {Link} from "react-router-dom";

const Listing = ({p,type}) => {
    return (
        <>
        {
        type === "sub_categories" ?
        ( 
        <div className="container" key={p.slug}>
            
                
                
                    <ul className="listing">
                        {
                            p.children.map((sp)=>(
                                <li key={sp.slug}>
                                    <Link to={`/app/product/${p.slug}/${sp.slug}`} className="btn btn-link">
                                        <img src={`${process.env.REACT_APP_BASE_URL}backend/uploads/sub_category/full/${sp.image}`} alt="" />
                                        <p>{sp.title}</p>
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                
               
            
        </div>
        ) : 
        (
            <ul className="listing">
            
                {
                    p.map((product)=> (
                        <li key={product.slug}>
                            <Link to={`/app/product/${product.slug}`} className="btn btn-link">
                                <img src={`${process.env.REACT_APP_BASE_URL}backend/uploads/category/full/${product.image}`} alt="" />
                                <p>{product.title}</p>
                            </Link>
                        </li>
                    ))
                }
                    
                
            
            </ul> 
        )
        }
        </>
    );
}

export default Listing;
