import React from 'react';
const Modal = (props) => {
    return (
        <div className="modal active" ref={props.modal}>
            <a href="" onClick={() => props.onDone()} className="modal-overlay" aria-label="Close"></a>
            <div className="modal-container">
                <div className="modal-header">
                <a onClick={() => props.onDone()} className="btn btn-clear float-right" aria-label="Close"></a>
                <div className="modal-title h5">{props.title}</div>
                </div>
                <div className="modal-body">
                <div className="content">
                    {props.children}
                </div>
                </div>
                <div className="modal-footer">
                    <a className="main-btn" onClick={() => props.onDone()}>{props.btnName}</a>
                </div>
            </div>
        </div>
    );
}

export default Modal;
