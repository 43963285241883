import React, {useContext, useEffect,useState, useRef} from 'react';
import {QueryContext} from '../context/queryProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import {Link} from 'react-router-dom';
//import PDF from "react-pdf-js";
import { Document,pdfjs,Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var BASE64_MARKER = ';base64,';

function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
const Review = () => {
    const [query,setQuery] = useContext(QueryContext);
    const [queryLocal,setQueryLocal] = useLocalStorage('query');
    const [item,setItem] = useLocalStorage('cart',[]);
    const [cart,setCart] = useState(item);
    const [isAlready,setAlready] = useState(false);
    const [added,setAdded] = useState(false);
    const [loading,setLoading] = useState(false);
   
    const canvasRef = useRef(null);
    const [page,setpage] = useState(1);
    const [front,setFront] = useState("");
    const [back, setBack] = useState("");
    const [pdfDocument,setPdfDocument] = useState("");
    const [width,setWidth] = useState(0);
    useEffect(()=>{
        //item && setCart(item)
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 
        //         'Accept': 'application/json'  ,
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({ 
        //         query_id: query.queryId,
        //     })
        // };
        // //console.log(requestOptions);
        // fetch(`${process.env.REACT_APP_BASE_URL}backend/api/order/preview`, requestOptions)
        //     .then(async response => {
        //         const data = await response.json();
        //         setFront(data.front_side)
        //         console.log(data);
        //      //   item  && item.length !== 0 && setCart(item) 
                
        //         // setCart(prev => (
        //         //     [...prev,{
        //         //         query_id:query.queryId,
        //         //         category: query.hasOwnProperty('sub_category') ? query.sub_category : query.category
        //         //     }]
        //         // ))

                
        //     })
        //     .catch(error => {
        //         //this.setState({ errorMessage: error.toString() });
        //         console.error('There was an error!', error);
        //     });
        fetch(`${process.env.REACT_APP_BASE_URL}backend/api/pdftest`)
        .then(async res => {
            const data= await res.json();
            setPdfDocument(`data:application/pdf;base64,${data}`);
           // setPdfDocument()
           // setPdfDocument(convertDataURIToBinary(`data:application/pdf;base64,${data}`));
            
        })

        setQuery(prev => (
            {
                ...prev,
                thumbnail_front: "https://adlizard.4pm.ie/Transactions/80021AB2/8005B74C/docPic.jpg",
                thumbnail_back: "https://adlizard.4pm.ie/Transactions/80002EC2/8005880E/docPic.jpg" 
            }
            
        ))
        
    },[])
  

    const handleOrder = () => {
       // console.log(cart);
       //item.length !== 0 && setCart(item)
       setLoading(true);
       if(item && item.length !== 0 && item.some(item => item.query_id === query.queryId)) {
           setAlready(true);
           setLoading(false);
           return false;
        }
    //    setCart(prev => (
    //        [...prev,{
    //         query_id:query.queryId,
    //         category: query.hasOwnProperty('sub_category') ? query.sub_category : query.category
    //     }]
    //    ))

       //setItem(cart);
       //setAdded(true);

       //getprices
       const apiUrl = `${process.env.REACT_APP_BASE_URL}backend/api/price/${query.category}`;
        fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
            setCart(prev=> (
                [...prev,{
                    prices: data.hasOwnProperty('prices') ? data.prices:[],
                    quantity: data.hasOwnProperty('quantity') ? data.quantity:[],
                    additional_price: data.hasOwnProperty('additional_price') ? data.additional_price : "",
                    additional_qty: data.hasOwnProperty('additional_qty') ? data.additional_qty : "",
                    query_id:query.queryId,
                    category: query.hasOwnProperty('sub_category') ? query.sub_category : query.category
                }]
                
            ))
            setAdded(true)
            setLoading(false)
            //cart.length !== 0 && setItem(cart)
            //art.length !== 0 && setAdded(true)
        });
    }
    useEffect(()=>{

        cart.length !== 0 && setItem(cart)
        
    },[cart])
    const handleComplete = () => {
        //console.log(data._transport._params.data);
        //console.log(canvasRef.current);
        setWidth(canvasRef.current.clientWidth);
       
    }
   
    return (
        <div className="container preview_wrapper">
            <div className="title">
                <p className="text top">Finish & Approve</p>
                <h2>Preview of Your Order</h2>
                <p className="text">This is your final proof before ordering! No changes can be made after approval.</p>
               
                {
                    isAlready ? 
                    <p className="dim-btn">Already Added to Cart</p>
                    :
                    !added && <a className="main-btn" onClick={handleOrder}>{loading ? "Loading..." : "Add to Cart"}</a>
                }
                {
                    added && <Link to="/app/cart" className="main-btn">View Cart</Link>
                }
            </div>
            <div className="chooser" ref={canvasRef}>
                <div className="preview">
                    
                   
                    {/* {
                        pdfDocument !== "" &&  <PDF file={pdfDocument} onDocumentComplete={handleComplete} />
                    } */}
                    {
                        pdfDocument !== "" && <Document
                        file={pdfDocument}
                        options={{
                            renderMode: "svg"
                        }}
                        onLoadSuccess={handleComplete}
                      >
                        <Page pageNumber={1} width={width}/>
                      </Document>
                    }
                    
                </div>
            </div>
        </div>
    )
}
export default Review;