import React,{useState,useEffect} from 'react';

const OffNav = (props) => {
    const [isClicked,setClick] = useState(false);
    const handleClick = () => {
        setClick(true);
    }
    // useEffect(()=>{
    //     isClicked ? 
    //     document.querySelector('#sidebar-id').style.transform = "translateX(0)"
    //      : 
    //     document.querySelector('#sidebar-id').style.transform = "translateX(-100%)"
    // },[isClicked])
    return (
        <div className="off-canvas off-nav">
            <a className="off-canvas-toggle btn" href="#sidebar-id" onClick={handleClick}>
                <i className="icon icon-menu"></i>
            </a>

            <div id="sidebar-id" className="off-canvas-sidebar">
            {props.children}
            </div>

            <a className="off-canvas-overlay" href="#close"></a>

            <div className="off-canvas-content">
                
            </div>
        </div>
    );
}

export default OffNav;
