import React,{useRef,useEffect,useState,useContext, useLayoutEffect} from 'react';
import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';
import Preview from './Preview';
import Selector from './Selector';
// import {products,getProducts} from "../API/data";
import Listing from './Listing';
import {QueryContext} from '../context/queryProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import male from '../male.svg';
import {Link} from 'react-router-dom';
const Chooser = ({match}) => {

    //var product = products.filter((p) => p.slug === match.params.slug);
   const [ratio,setRatio] = useState({});
   const [name,setName] = useLocalStorage('query',{});
    const [query,setQuery] = useContext(QueryContext);
    const [stepCount,setStepCount] = useState(0);
    const [stepPos,setStepPos] = useState(query.stepPos);
    const [title,setTitle] = useState("");
    const [isInside,setIsInside] = useState(false);
   // const [height,setHeight] = useState(0);
    //const [width,setWidth] = useState(0);
    const showcase = useRef(null);
    const canvasImage = useRef(null);
    const markup = useRef(null);
    const [preview,setPreview] = useState("");
    const [product,setProduct] = useState([]);
    const [data,setData] = useState({});
    const [loaded,setLoading] = useState(false);
    const [stepOver,setStepOver] = useState(false);
    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}backend/api/get_products`;
        fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
            setProduct(data.filter((p) => p.slug === match.params.slug));
            var res = data.filter((p) => p.slug === match.params.slug);
            var height = res[0].height/gcd(res[0].width,res[0].height)*getScale(res[0].height/gcd(res[0].width,res[0].height));
            //console.log(res[0].width);

            
            setRatio({
                    wr: res[0].width/gcd(res[0].width,res[0].height),
                    hr: res[0].height/gcd(res[0].width,res[0].height),
                    scale: getScale(res[0].height/gcd(res[0].width,res[0].height)) ,
                    stage: showcase.current == null ? 0 : showcase.current.clientWidth
                });
                setData({
                    
                        name: "John Doe",
                        address: "USA",
                        date: "12/02/2050",
                        age: "200",
                        verse: "Christ has died; Christ is rise n; Christ will come again.",
                        img_src: male,
                        border_style: "normal",
                        gender: "male",
                        half_height: height*0.45,
                        photo_height: height/3,
                        photo_width: 150,
                        normal_size: height*0.03,
                        big_size: height*0.05,
                        title_size: height*0.04
                    
                })
                setQuery(prev => (
                    {
                            ...prev,
                            height: match.params.hasOwnProperty('spslug') ? res[0].children.filter((sp=>(sp.slug === match.params.spslug)))[0].height : res[0].height,
                            width: match.params.hasOwnProperty('spslug') ? res[0].children.filter((sp=>(sp.slug === match.params.spslug)))[0].width : res[0].width,
                            sub_category: match.params.spslug,
                            category: match.params.slug,
                            stepPos: 0
                        
                    }
                ));
        });
        return () => {
           // setQuery(query);
            //setStepPos(0);
            //setQuery({})
        }
    }, [match.params]);
    useEffect(() => {
        //setStepPos(query.stepPos)
        setStepPos(name.hasOwnProperty('stepPos') ? name.stepPos : 0);
        setQuery(prev => (
            {
                    ...prev,
                    category: match.params.slug,
                    sub_category: match.params.spslug,
                    stepPos: 0
                
            }
        ));
        //sconsole.log(getProducts("http://jjlalor-ie.stackstaging/app/backend/api/get_products"));
       // console.log(product);
        // setRatio({
        //     wr: product.width/gcd(product.width,product.height),
        //     hr: product.height/gcd(product.width,product.height),
        //     scale: getScale(product.height/gcd(product.width,product.height)) ,
        //     stage: showcase.current.clientWidth
        // });
        //console.log(showcase.current);
        // document.getElementById('template').innerHTML = query.cover_html;
        // document.getElementById('template').style.height = "700px";
        // document.getElementById('template').style.width = "1010px";
        
        
        // product.map((p) =>{
        //     setWidth(p.width)
        //     setHeight(p.height)
        // }
        // )

    }, []);
    const go = () => {
        if(stepPos >= stepCount - 1){
            return false;
        } 
        if(stepPos >= stepCount - 2) setStepOver(true);
        setStepPos(x => x + 1) 
        //console.log(stepPos);
        setQuery(prev => (
            {
                    ...prev,
                    stepPos: stepPos+1
                
            }
        ));
        
        
    }
    const back = () => {
        if(stepPos < 1) return false;
        setStepOver(false)
        setStepPos(x => x - 1)
        setQuery(prev => (
            {
                    ...prev,
                    stepPos: stepPos-1
                
            }
        ));
        
       
    }

    const gcd = (a,b) => {
        return (b === 0) ? a : gcd(b, a%b);
    };
    const getScale = (hr) => {
       // console.log(showcase.current.clientHeight,showcase.current.clientWidth/2);
        return showcase.current == null ? false : showcase.current.clientHeight/hr;
    }
    // const setSize = (scale,wr,stage) => {
    //     var newScale = 0;
    //     for(var i = 1; i <= Math.round(scale); i++){
            
    //         if((i*wr) <= stage){
    //             newScale = i;
    //         }
            
    //     }
    //     //console.log("l"+newScale);
    //     return newScale;
        
    // }
    const getDimension = () => {
        return 5
    }
    return (
        <>
        {
            product.map((p) => (
                match.params.hasOwnProperty('slug') ?
                (
                        p.children.length === 0 || match.params.hasOwnProperty('spslug') ? (

                        <div className="container" key={p.slug}>
                            <div className="chooser">
                                <div className="left">
                                    <div className="header">
                                        <h4><span className="ico"></span>{title}</h4>
                                    </div>
                                    <div className="body">
    
                                    <Selector 
                                    key={p.slug} 
                                    catId={p.slug}
                                    setStepCount={setStepCount} 
                                    stepCount={stepCount} 
                                    stepPos={query.hasOwnProperty('stepPos') ? query.stepPos : stepPos}
                                    isCached={query.hasOwnProperty('stepPos') ? true : false}
                                    setTitle={setTitle}
                                    width={p.width}
                                    height={p.height}
                                    setPreview={setPreview}
                                    setIsInside={setIsInside} 
                                    loaded={loaded}
                                    setLoading={setLoading}
                                    slug={p.slug}
                                    spslug={match.params.hasOwnProperty('spslug') ? match.params.spslug : null}
                                    />
    
                                    
                                    </div>
                                    <div className="footer">
                                        <a className="dim-btn"  onClick={back}>Back</a>
                                        {
                                            stepOver ? (
                                                <Link to="/app/review" className="main-btn">Next</Link>
                                            ):
                                            (
                                                <a className="main-btn" onClick={go}>Next</a>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="showcase">
                                        {/* <div 
                                        className="preview-left" 
                                        style={{width: 0 || setSize(ratio.scale,ratio.wr,ratio.stage)*ratio.wr,height: 0 || ratio.hr*setSize(ratio.scale,ratio.wr,ratio.stage)}}
                                        ref={el}>
                                            <img src={logo} alt="logo"/>
                                            <p>paragraph....</p>
                                        </div> */}
                                        {/* <div className="preview-right" style={{width:ratio.wr*ratio.scale,height: ratio.hr*ratio.scale}}></div> */}
                                        
                                        <div 
                                        className="preview-right"
                                        ref={showcase} 
                                        style={{width:ratio.wr*ratio.scale,height: ratio.hr*ratio.scale}}
                                        >
                                            <Preview 
                                            preview={preview} 
                                            canvasImage={canvasImage} 
                                            markup={markup} 
                                            height={ratio.hr*ratio.scale}
                                            isInside={isInside}
                                            data={data}
                                            loaded={loaded}/>
                                        </div>
                                    </div>
                                    <div className="text"><p>Preview of Your Card</p></div>
                                </div>
                            </div>
                        </div>
                    
                    ) : (
                        <Listing p={p} key={p.slug} type="sub_categories" />
                     )
                    
                )
                :
                
                   null
                
            ))
        }
        </>
    );
}

export default Chooser;
