import React,{useState,useEffect} from 'react';
import {CardNumberElement,CardExpiryElement,CardCvcElement,useStripe,useElements} from '@stripe/react-stripe-js';

const StripePayment = ({order}) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        // window
        //   .fetch("/create.php", {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
        //   })
        //   .then(res => {
        //     return res.json();
        //   })
        //   .then(data => {
        //     setClientSecret(data.clientSecret);
        //   });
      }, []);
  
    const options = {
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#567383',
            color: '#3b4351',
            fontWeight: 500,
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {color: '#bcc3ce'},
            '::placeholder': {color: '#bcc3ce'}
          },
          invalid: {
            iconColor: '#cd2653',
            color: '#cd2653',
          },
        },
      };

      const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
      };
      const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        // const payload = await stripe.confirmCardPayment(clientSecret, {
        //   payment_method: {
        //     card: elements.getElement(CardNumberElement),
        //     billing_details: {
        //         name: order.name,
        //         country: order.country
        //       }
        //   }
        // });
        // if (payload.error) {
        //   setError(`Payment failed ${payload.error.message}`);
        //   setProcessing(false);
        // } else {
        //   setError(null);
        //   setProcessing(false);
        //   setSucceeded(true);
          
        // }
        //const {token, error} = await stripe.createToken(elements.getElement(CardNumberElement));
        const {token, error} = await stripe.createToken(elements.getElement(CardNumberElement))
        if(error) {
            setError(error.message);
            setProcessing(false);
        }else{
            setError(null);
            setProcessing(false);
            //console.log(token);
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept': 'application/json'  ,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    stripeToken: token.id,
                    amount: order.total_price(order.sub_total(order.items),order.shipping_cost),
                    currency: 'EUR',
                    description: `test`
                })
            };
            //console.log(requestOptions);
            fetch(`${process.env.REACT_APP_BASE_URL}backend/api/payment`, requestOptions)
                .then(async response => {
                    const data = await response.text();
                    console.log(data);
                })

        }
      };
    
    return (
        <div className="payment-wrap">
            <h4>Card Details</h4>
            <div className="form-payment">
                
                 
                        <form className="form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="form-label">Card Holder name</label>
                            <input className="form-input" id="name" type="text" placeholder="Name"/>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Card Number</label>
                            <CardNumberElement options={options} className="inputs" onChange={handleChange}/>
                            
                        </div>
                        <div className="form-group">
                            <div className="columns">
                                <div className="col col-6 col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">CVC</label>  
                                        <CardCvcElement options={options} className="inputs" onChange={handleChange}/>
                                    </div>
                            
                                </div>
                                <div className="col col-6 col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Expiry Date</label>  
                                        <CardExpiryElement options={options} className="inputs" onChange={handleChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={processing || disabled || succeeded}
                            id="submit"
                            className="main-btn"
                        >
                            <span id="button-text">
                            {processing ? (
                                <div class="loading loading-lg"></div>
                            ) : (
                                "Pay Now"
                            )}
                            </span>
                        </button>
                        {error && (
                            <div className="card-error" role="alert">
                            {error}
                            </div>
                        )}
                        </form>
            </div>
        </div>
    )
}
export default StripePayment;