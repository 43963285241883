import React,{useEffect} from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
export const QueryContext = React.createContext();

export const QueryProvider = props => {
    const [name, setName] = useLocalStorage('query',{});
    // const [query,setQuery] = React.useState({
    //     stepPos: name.hasOwnProperty('stepPos') ? name.stepPos : 0,
    //     coverId: name.hasOwnProperty('coverId') ? name.coverId : ""
    // });
    const [query, setQuery] = React.useState({});

    useEffect(() => {
        setQuery(name)
    },[])

    useEffect(() => {
        setName(query);
    },[query])

    return (
        <QueryContext.Provider value={[query,setQuery]}>
            {props.children}
        </QueryContext.Provider>
    )
}