import React,{useState,useContext,useEffect} from 'react';
import Modal from './Modal';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {QueryContext} from '../context/queryProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';
import html2canvas from 'html2canvas';

const Cover = ({body,width,height,hasAction,setIsInside,loaded,setLoading,stepPosition}) => {
    const [photo, setPhoto] = useLocalStorage('photo',"");
    const [qry,setQry] = useLocalStorage('query',{});
    const [query,setQuery] = useContext(QueryContext);
    const [isModalOpen,setmodal] = useState(false);
    const [coverhtml,setCoverHtml] = useState("");
    const [queryId,setQueryId] = useState(0);

    const editorConfiguraiton = {
        fontFamily: {
                    options: [
                        'default',
                        'Ubuntu, Arial, sans-serif',
                        'Ubuntu Mono, Courier New, Courier, monospace'
                    ]
                },
        toolbar: {
            items: ['bold','italic','heading','undo','redu','justifycenter']
        }
    }
    useEffect(()=>{
        //console.log(coverhtml);
        document.getElementById('template').innerHTML = coverhtml;
        // htmlToImage.toPng(document.getElementById('template'))
        // .then(function (dataUrl) {
        //     // var img = new Image();
        //     // img.src = dataUrl;
        //     // document.body.appendChild(img);
        //     setQuery(prev => (
        //         {
        //                 ...prev,
        //                 cover_image: dataUrl
                    
        //         }
        //     ));
        //     document.getElementById('template').style.display="none";
        // })
        // .catch(function (error) {
        //     console.error('oops, something went wrong!', error);
        // });
        html2canvas(document.getElementById('template'),{
            allowTaint: true,
            useCORS: true,
            imageTimeout: 50000,
            width: width,
            height: height
        }).then(function(canvas) {
           //document.body.appendChild(canvas);
           //console.log(canvas.toDataURL);
           //document.getElementById('template').style.display="none";
          setQuery(prev => (
                    {
                            ...prev,
                            cover_image: canvas.toDataURL('image/png'),
                            cover_html: document.getElementById('template').innerHTML
                        
                    }
                 ));
                 setLoading(false);
                // document.querySelector('#template').style.display = "none";
        });
    },[coverhtml]);
    //const [body, setBody] = useState('');
    // ClassicEditor.defaultConfig = {
    //     fontFamily: {
    //         options: [
    //             'default',
    //             'Ubuntu, Arial, sans-serif',
    //             'Ubuntu Mono, Courier New, Courier, monospace'
    //         ]
    //     },
    //     toolbar: {
    //         items: [
    //             'bold',
    //             'italic',
    //             'fontFamily',
    //             '|',
    //             'bulletedList',
    //             'numberedList',
    //             'indent',
    //             'outdent',
    //             '|',
    //             'heading',
    //             '|',
    //             'undo',
    //             'redo'
    //         ]
    //     },
        
    // };
    const onSelectFile = () => {
        setmodal(true);
    }
    const onDone =() => {
        setmodal(false);
    }
    

    const handleSubmit = (e) => {
        e.preventDefault()
        //onSubmit({ body })
    }
    // const handleQuery = (v,border,verse) => {
    //     setQuery(prev => (
    //         {
    //                 ...prev,
    //                 coverId: v
                
    //         }
    //     ));
    //     if(query.stepPos > 1) setIsInside(true)
    //     else setIsInside(false)
    //    // setName(query);
    //    switch(query.stepPos){
    //     case 2:
    //         setQuery(prev => (
    //             {
    //                 ...prev,
    //                 data: {
    //                     ...prev.data,
    //                     border_style: border
    //                 }
    //             }
    //         ))
    //         break;
    //     case 4:
    //         setQuery(prev => (
    //             {
    //                 ...prev,
    //                 data: {
    //                     ...prev.data,
    //                     verse: verse
    //                 }
    //             }
    //         ))
    //         break;
    //     case 5:
            
    //         break;
    //    }
       
        
    // }
    const handleQuery = (v,url,type,border,stepPos) => {
        setLoading(true);
        setQuery(prev => (
                    {
                            ...prev,
                            coverId: v,
                            border_style: border
                        
                    }
                ));
               // console.log(coverhtml);
               
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Accept': 'application/json'  ,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                query_id: queryId !== 0 ? queryId : query.queryId ,
                step_position: query.hasOwnProperty('stepPos') ? query.stepPos+1 : stepPos,
                category: query.hasOwnProperty('category') ? query.category : "",
                sub_category: query.hasOwnProperty('sub_category') ? query.sub_category : "", 
                front_cover: type === "1" ? v : query.coverId,
                back_cover: type === "2" ? v : query.coverId,
                gender: query.data.hasOwnProperty('gender') ? query.data.gender : "male",
                inside_left: type === "3" ? v : query.coverId,
                inside_right: type === "4" ? v : query.coverId,
                name: query.data.hasOwnProperty('name') ? query.data.name : "Name",
                address: query.data.hasOwnProperty('address') ? query.data.address : "Address",
                dod: query.data.hasOwnProperty('date') ? query.data.date : "Age",
                age: query.data.hasOwnProperty('age') ? query.data.age : "Date",
                photo: photo,
                border: border
            })
        };
        //console.log(requestOptions);
        fetch(`${process.env.REACT_APP_BASE_URL}backend/api/query`, requestOptions)
            .then(async response => {
                const data = await response.text();
    
                // check for error response
                // if (!response.ok) {
                //     // get error message from body or default to response status
                //     const error = (data && data.message) || response.status;
                //     return Promise.reject(error);
                // }
    
                //this.setState({ postId: data.id })
                //console.log(data);
                // setQuery(prev => (
                //     {
                //             ...prev,
                //             cover_html: data
                        
                //     }
                // ));
                
                setCoverHtml(data);
                
                
                // for (var pair of response.headers.entries()) {
                //     console.log(pair[0]+ ': '+ pair[1]);
                //   }
                //console.log(document.getElementsByTagName('table'));
               //console.log(response.headers.get('query_id'));
                var parser = new DOMParser();
                var temp = parser.parseFromString(data, "text/html").documentElement;
              
                setQueryId( temp.querySelector('#query_id').innerText )
                setQuery(prev => (
                    {
                            ...prev,
                            queryId: temp.querySelector('#query_id').innerText
                        
                    }
                ));
                setLoading(false);
                //setQuery(temp.querySelector('#query_id').innerText)

            })
            .catch(error => {
                //this.setState({ errorMessage: error.toString() });
                console.error('There was an error!', error);
                setLoading(false);
            });
    }
    return (
        <>
        <div className="wrap-cover">
            <ul>
                {
                    hasAction && (
                        <>
                        <li onClick={onSelectFile} className="action">
                                <label htmlFor="photo">Click to Add Your Own Verse</label>
                        </li>
                        {
                            isModalOpen && (
                                <Modal title="Create Your Own Verse" onDone={onDone} btnName="Okay">
                                    <form onSubmit={handleSubmit} className="form">
                                    {/* <div className="form-group">
                                        <label className="form-label">Name</label>
                                        <input className="form-input" type="text" placeholder="Name"/>
                                    </div> */}
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        config={editorConfiguraiton}
                                        data="<p>Hello from CKEditor 5!</p>"
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            console.log( { event, editor, data } );
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            console.log( 'Focus.', editor );
                                        } }
                                    />
                                        
                                    </form>
                                </Modal>
                            )
                        }
                        </>
                        
                    )
                }
                {
                    body.map((b,c)=>(
                        <li key={c} onClick={()=>handleQuery(b.photo,`${process.env.REACT_APP_BASE_URL}backend/uploads/cover/full/${b.photo}`,b.cover_type,b.name,stepPosition)}>
                            <img src={`${process.env.REACT_APP_BASE_URL}backend/uploads/cover/full/${b.photo}`} style={{width: width,height: height}} alt={b.name} />   
                        </li>
                    ))
                }
                
            </ul>
            
        </div>
        <div className="mobile-slider">
            <ul>
                {
                    body.map((b)=>(
                        <li key={b.photo} onClick={()=>handleQuery(b.photo,`${process.env.REACT_APP_BASE_URL}backend/uploads/cover/full/${b.photo}`,b.cover_type,b.name,stepPosition)}>
                            <img src={`${process.env.REACT_APP_BASE_URL}backend/uploads/cover/full/${b.photo}`} alt={b.name} />   
                        </li>
                    ))
                }
                
            </ul>
        </div>

        
        
        </>
    );
}

export default Cover;
