import React,{useEffect,useState} from 'react';
import Choice from './Choice';
import {steps,getSteps} from "../API/data";
const Selector = (props) => {
    const step = steps.filter((s) => s.cat_id === props.catId);
    const [stepState,setStepState] = useState(0);
    const [sortedStep,setSortedStep] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const sort_by_key = (array, key) =>
        {
        return array.sort(function(a, b)
        {
        var x = a[key]; 
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        }
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}backend/api/steps/${props.slug}/${props.spslug}`)
            .then(res => res.json())
            .then(
            (result) => {
                setIsLoaded(true);
                setSortedStep(sort_by_key(result,'step_position'));
                props.setStepCount(result.length);
                
                console.log(stepState);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
            )
        }, [])
    useEffect(() => {
       // props.setStepCount(step.length !== 0 && step[0].hasOwnProperty("steps") ? step[0].steps.length : 0);
        setStepState(props.stepPos);
        //console.log(getSteps());
        //setSortedStep(sort_by_key(step.length !== 0 && step[0].hasOwnProperty("steps") ? step[0].steps : [],'step_pos'));
        //console.log(sortedStep);
    }, [props.stepPos]);
    
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>
    } else{
        return (
            
            <>
            {/* {JSON.stringify(sortedStep[stepState])} */}
                <Choice 
                step={sortedStep.length !== 0 ? sortedStep[stepState] : []}
                setTitle={props.setTitle} 
                width={props.width}
                height={props.height}
                setPreview={props.setPreview}
                setIsInside={props.setIsInside}
                loaded={props.loaded}
                setLoading={props.setLoading}
                stepPosition={sortedStep.length !== 0 ? sortedStep[stepState].step_position : 0} />
                {
                    sortedStep.length !== 0  ? (
                        sortedStep[stepState].step_desc !== "NA" && (
                                <div className="caption">
                                    <p>{sortedStep[stepState].step_desc}</p>
                                </div>
                            )
                        
                    ) : null
                }
            </>
        )
    }
}

export default Selector;
