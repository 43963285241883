import './App.scss';
import React,{useEffect,useState} from 'react';
import {QueryProvider} from './context/queryProvider';
import Chooser from './components/Chooser';
import CartTable from './components/CartTable';
import Nav from './components/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Listing from './components/Listing';
import Review from './components/Review';
//import {products} from './API/data';
import useSWR from 'swr';
const fetcher = (...args) => fetch(...args).then(res => res.json());
function App() {

  return ( 
    <Router>
      <div className="container"> 
        <Nav/>
      </div>
      <Switch>
        <Route path="/app" exact component={Home} />
        <Route path="/app/about" exact component={About} />
        <Route path="/app/pricing" exact component={Pricing} />
        <Route path="/app/cart" exact component={Cart} />
        <QueryProvider>
          <Route path="/app/product/:slug" exact component={Chooser} />
          <Route path="/app/product/:slug/:spslug" exact component={Chooser} />
          <Route path="/app/review" exact component={Review} />
        </QueryProvider>
        
      </Switch>
      
    </Router>
  );
}

const Home = () => {
  // function getProducts () {
  //   const { data, error } = useSWR(`http://jjlalor-ie.stackstaging/app/backend/api/get_products`, fetcher)
  //   return {
  //     user: data,
  //     isLoading: !error && !data,
  //     isError: error
  //   }
  // }
  //const { user, isLoading, isError } = getProducts();
  // if(isLoading) return "loading"
  // if(isError) return "error"
  const [products,setProducts] = useState([]);
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}backend/api/get_products`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);
  
  return (
    <div className="container">
        <Listing p={products} />
    </div>
  )
}

const About = () => {
  return (
    <h1>About</h1>
  )
}
const Pricing = () => {
  return (
    <h1>Pricing</h1>
  )
}
const Cart = () => {
  return (
    <>
      <CartTable />
    </>
  )
}

export default App;
