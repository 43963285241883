import React,{useEffect,useState} from 'react';
import {QueryContext} from '../context/queryProvider';
import {template} from '../API/data';
import Mustache from 'mustache';
import male from '../male.svg';
import female from '../female.svg';
const Loader = () => {
    return (
        <div class="loading loading-lg"></div>
    )
}
const Preview = ({preview,canvasImage,markup,height,isInside,data,loaded}) => {
    const [query,setQuery] = React.useContext(QueryContext);
    
    
    useEffect(() => {
        //document.getElementById("template").innerHTML = template[0]["html"]
        //renderTemplate();
        // if(query.stepPos > 1){
        //     setIsInside(true);
        // } 
        setQuery(prev => (
            {
                ...prev,
                data
            }
        ))
    },[isInside]);

    const renderTemplate = () => {
        const rendered = Mustache.render(template[0]["html"],query.data);
        //document.getElementById("template").innerHTML = rendered;
        return rendered;
    }

    return (
       
        <>
        {
            isInside ? (
                <>
                    <div dangerouslySetInnerHTML={{__html:query.cover_html}} className="template-loader" ref={markup}></div>
                    <div ref={canvasImage}></div>
                </>
            )
            :(
                //<img src={query.coverId !== null ? `http://jjlalor-ie.stackstaging.com/app/backend/uploads/cover/full/${query.coverId}` : `${process.env.REACT_APP_BASE_URL}cover/${preview}`} alt="" />
                <>
                {
                   loaded ? <Loader/> : <img src={query.cover_image} />
                }
                </>
                //<div dangerouslySetInnerHTML={{__html:query.cover_image.}}></div>
                )
        }
        </>
        );
}

export default Preview;
