import { toSvgDataURL } from 'html-to-image';
import React,{useState,useEffect} from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import PaymentForm from './PaymentForm';
import ShippingZone from './ShippingZone';
import StripePayment from './StripePayment';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe('pk_test_51IGRRSBVo6EQdRin4DH5GRkEAiVqOza6sfZWsU6nqlaLdx0NNfzf9cnJk5uxBLSGoCLFFNQDZEkoDXO8PBNd5gFD00dlqc5cNt');
const Details = ({cartItem,order,setOrder}) => {
    const [items,setItems] = useLocalStorage('cart',[]);
    const [price,setPrice] = useState(cartItem.prices);
    const [qty,setQty] = useState(cartItem.quantity);
    const [stage,setStage] = useState(0);
    const [newStats,setNewStats] = useState({
        price: cartItem.prices[0],
        qty: cartItem.quantity[0]
    });
    const [cart,setCart] = useState({
        qty: 0,
        price: 0,
        product_image: ["abcdf", "ddd"],
        category: cartItem.category
    });
    
    const [add,setAdd] = useState({
        price: cartItem.additional_price,
        qty: cartItem.additional_qty
    });
    const [isExceed,setIsExceed] = useState(false);
    
    
    
    
    const handleButton = action => {
        
       if(action === "plus"){
        
        if(stage > price.length - 2){
            setIsExceed(true);
            setNewStats(prev => ({
                ...prev,
                price: parseInt(newStats.price)+parseInt(add.price),
                qty: parseInt(newStats.qty)+parseInt(add.qty)
            }))
           
        }else{
            setIsExceed(false);
            setStage(x => x + 1) ;
        }
        
       }else if(action === "minus"){
        
            if(stage < price.length-2){
                //console.log(stage)
                setIsExceed(false);
                if(stage < 1) return false;
                setStage(x => x - 1) ;
                
            }else{
                setIsExceed(true);
                
                setNewStats(prev => ({
                    ...prev,
                    price: parseInt(newStats.price)-parseInt(add.price),
                    qty: parseInt(newStats.qty)-parseInt(add.qty)
                }))
            }
       } 
       
              
        
    }
    
    
    useEffect(()=>{
        
        setCart(prev => ({
            ...prev,
            price: isExceed ? newStats.price : price[stage],
            qty: isExceed ? newStats.qty : qty[stage]
        }))
        // setCart({
        //     price: price[stage],
        //     qty: qty[stage]
        // })
    },[stage,newStats])

    useEffect(()=>{
       setOrder(prev => ({
        ...prev,
        items: [...prev.items,cart]
       })) 
    },[])
    useEffect(()=> {
       // !order.items.some(item => item.category === cart.category) && 
        // order.items.length !== 0 && setOrder((order) => (order.items.map((item) => item.category === cart.category ? {
        //     ...item,
        //     qty: cart.qty,
        //     price: cart.price
        // } : item)))
        //order.items.length !== 0 && console.log(order.items.map((item) => (item.qty)));
        order.items.length !== 0 && setOrder((prev) => {
            if(order.items.some(item => item.category === cart.category) ){
                let found = order.items.find(item => item.category === cart.category)
                //let findIndex = order.items.indexOf(found);
                //delete order.items[found];
                if(found){
                    found.qty = cart.qty;
                    found.price = cart.price;
                }
                return {
                    ...prev
                }
                
                
            }
        })
    },[cart])
   
   

    return (
        <div className="details">
            <h5 className="title">{cartItem.category}</h5>
            <div className="quantity">
                <div className="input-group">
                    <button className="btn input-group-btn btn-sm" onClick={()=>handleButton("minus")}>-</button>
                    <input className="form-input input-sm" type="text" value={isExceed ? newStats.qty : qty[stage]}/>
                    <button className="btn input-group-btn btn-sm" onClick={()=>handleButton("plus")}>+</button>
                </div>
            </div>
            
            <p className="price text"><span className="symbol">&euro;</span>{isExceed ? newStats.price : price[stage]}</p>
        </div>
        
    )
};
const CartTable = ({}) => {
    const [cart,setCart] = useLocalStorage('cart');
    const [data,setData] = useState(cart || []);
    const [orderSaved,setOrderSaved] = useState(false);
    const [orderId,setOrderId] = useLocalStorage('order',[]);
    const [loading,setLoading] = useState(false);
    const [openCart,closeCart] = useState(true);
    const [order,setOrder] = useState({
        order_name: "",
        address1: "",
        address2: "",
        address3:  "",
        phone: "",
        email: "",
        post_code: "",
        country: "",
        items: [],
        sub_total: (items)=>{
            var sum = 0;
            for(var i=0;i<items.length;i++){
                sum = sum + parseInt(items[i].price);
            }
            return sum
        },
        shipping_cost: 0,
        total_price: (subtotal,shipping_cost) => {
            return parseInt(subtotal) + parseInt(shipping_cost)
        },
        shipping_zone: ""
    });
    const handleRemove = category => {
        setData(data.filter(jsonObj => (jsonObj.category !== category)))
    }
   
    // useEffect(() => {
    //     setOrder(prev => ({...prev,sub_total: sum}))
    //     getSum(order.items)
    // },[])
    const handleCheckOut = () => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Accept': 'application/json'  ,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                order_name: order.order_name ,
                address_one: order.address1,
                address_two: order.address2,
                address_three: order.address3, 
                country: order.country,
                postal_code: order.post_code,
                shipping_zone: order.shipping_zone,
                email: order.email,
                phone: order.phone,
                items: order.items
            })
        };
        //console.log(requestOptions);
        fetch(`${process.env.REACT_APP_BASE_URL}backend/api/order`, requestOptions)
            .then(async response => {
                const data = await response.json();
                if(data.msg === "success") {
                    setOrderId(data.order);
                    setOrderSaved(true);
                    setLoading(false);
                    closeCart(false);
                }
            }
            );
    }
    return (
        <div className="cart">
            <div className="container">
                <div className="columns">
                    <div className="col col-8 col-md-12">
                        {
                            loading && (
                                <div className="loader">
                                    <div class="loading loading-lg"></div>
                                </div>
                            )
                        }
                        {
                            openCart && (
                                <>
                                <h4>Your Shopping Cart</h4>
                                {
                                    data.length !== 0 ?
                                    (
                                        <p className="para text">Please review your products below, fill out your delivery & shipping details and click on the payment button when you are ready.
                    You can pay using your credit card or alternatively with Paypal.</p>
                                    )
                                    :
                                    (
                                        <p className="para">You do not currently have any items in your shopping cart.</p>
                                    )
                                }
                                <div className="cart-table">
                                        {
                                            data.map((c,i)=>(
                                                <div className={i % 2 == 0 ? "cart_table_row" : "cart_table_row odd"} key={i}>
                                                    <div className="thumb">
                                                        image
                                                    </div>
                                                    <Details cartItem={c} order={order} setOrder={setOrder}/>
                                                    <div className="cart_action">
                                                        <a className="dim-btn" onClick={()=>handleRemove(c.category)}>Remove</a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                </div>
                                {
                                    data.length !== 0 &&  <PaymentForm setOrder={setOrder}/>
                                }
                                </>
                            )
                        }
                        
                        {
                            orderSaved && orderId.length !== 0 && (
                                <Elements stripe={stripePromise} options={{
                                        fonts: [{
                                            family: 'Poppins',
                                            src: 'url(https://fonts.googleapis.com/css2?family=Poppins)',
                                            weight: '400'
                                        }]
                                    }}>
                                    <StripePayment order={order}/>
                                </Elements>
                            )
                        }
                        
                       
                    </div>
                    {
                        data.length !== 0 && (
                            <div className="col col-4 col-md-12">
                                <h4>Summary</h4>
                                <table className="summary text">
                                    <tbody>
                                        <tr>
                                            <td>Subtotal:</td>
                                            <td>&euro;{order.sub_total(order.items)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Cost:</td>
                                            <td>&euro;{order.shipping_cost}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Price:</td>
                                            <td>&euro;{order.total_price(order.sub_total(order.items),order.shipping_cost)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <ShippingZone setOrder={setOrder}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div className="action_summary">
                                    <a className="main-btn" onClick={handleCheckOut}>Continue to Checkout</a>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default CartTable;

