import React,{useState} from 'react';
const PaymentForm = ({setOrder}) => {

   
    const handleChange = e => {
        switch(e.target.id){
            case "name":
                setOrder(prev => (
                    {...prev,order_name:e.target.value}
                ))
                break;
            case "a1":
                setOrder(prev => (
                    {...prev,address1:e.target.value}
                ))
                break;
            case "a2":
                setOrder(prev => (
                    {...prev,address2:e.target.value}
                ))
                break;
            case "a3":
                setOrder(prev => (
                    {...prev,address3:e.target.value}
                ))
                break;
            case "country":
                setOrder(prev => (
                    {...prev,country:e.target.value}
                ))
                break;
            case "postcode":
                setOrder(prev => (
                    {...prev,post_code:e.target.value}
                ))
                break;
            case "email":
                setOrder(prev => (
                    {...prev,email:e.target.value}
                ))
                break;
            case "phone":
                setOrder(prev => (
                    {...prev,phone:e.target.value}
                ))
                break;
        }
       // setOrder(prev => ({...prev,)
    }
    return (
        <div className="payment-wrap">
        <h4>Delivery & Shipping Details</h4>
        <div className="form-payment">
            <form id="" action="" className="form">
                <div className="form-group">
                    <div className="columns">
                        <div className="col col-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <input className="form-input" id="name" type="text" placeholder="Name" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Address Line1</label>
                                    <input className="form-input" id="a1"type="text" placeholder="" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Address Line2</label>
                                    <input className="form-input" id="a2" type="text" placeholder="" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Address Line3</label>
                                    <input className="form-input" id="a3" type="text" placeholder="" onChange={handleChange}/>
                                </div>
                        </div>
                        <div className="col col-6 col-md-12">
                        <div className="form-group">
                                    <label className="form-label">Country</label>
                                    <input className="form-input" id="country" type="text" placeholder="Country" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Postcode</label>
                                    <input className="form-input" id="postcode" type="text" placeholder="Postcode" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Email Address</label>
                                    <input className="form-input" type="text" id="email" placeholder="john@doe.com" onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Phone</label>
                                    <input className="form-input" type="text" id="phone" placeholder="Phone" onChange={handleChange}/>
                                </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </div>
    )
}
export default PaymentForm;