import React,{useEffect, useState} from 'react';
const ShippingZone = ({setOrder}) => {
    const [zone,setZone] = useState("");
    useEffect(()=>{
       
        zone !== "" && fetch(`${process.env.REACT_APP_BASE_URL}backend/api/shipping/${zone}`)
        .then(async response => {
            const data = await response.json(); 
            setOrder(prev => ({
                ...prev,
                shipping_cost: data.length !== 0 && data[0].cost || 0
            })) 
        })
        .catch(error => {
            //this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
    },[zone])

    const handleChange = e => {
        setZone(e.target.value)
        setOrder(prev => ({...prev,shipping_zone: e.target.value}))
    }
    return (
        <div className="form-group">
            <label className="form-label"><b>Shipping Zone</b></label>
            <select className="form-select select-lg" onChange={handleChange}>
                  <option value="Ireland">Ireland</option>
                  <option value="UK">UK</option>
                  <option value="Europe">Europe</option>
                  <option value="Asia">Rest of the World</option>
            </select>
        </div>
    )
}
export default ShippingZone;